<template>
  <div :style="computedStyle" v-show="!isHidden">
    <span class="registry_fields_label">
      {{ label }}
      <el-tooltip v-if="tooltip" class="item" effect="dark" :content="tooltip">
        <i class="el-icon-question"></i>
      </el-tooltip>
    </span>
    <el-form-item
      :prop="name"
      :rules="[
      { required: _isRequired, message: $locale.main.message.required_field, trigger: 'blur' },
      rules.controlValues
    ]">
      <el-date-picker
        :size="size"
        v-bind:value="value"
        class="date_field_width"
        v-on:input="$emit('input', $event)"
        type="date"
        :name="name"
        :placeholder="placeholder || $locale.main.placeholder.date_field"
        value-format="yyyy-MM-dd"
        format="dd.MM.yyyy"
        :readonly="readonly"
        :picker-options="{ firstDayOfWeek: 1 }">
      </el-date-picker>
    </el-form-item>
    <slot></slot>
  </div>
</template>

<script>
import mixin from '../mixins'
import registryMixin from '../registry/registry_mixins'
import controlValues from '../control_values_mixin'
export default {
  name: 'a-date',
  mixins: [mixin, registryMixin, controlValues],
  props: {
    controlValues: {
      type: Object,
      editor: 'ControlValues',
      description: 'Контроль значений',
      default: () => {
        return {
          operator: null,
          type: 'date',
          conditions: []
        }
      },
      options: { type: 'date' }
    },
    name: {
      type: String,
      description: 'Атрибут'
    },
    label: {
      description: 'Название',
      type: String
    },
    placeholder: {
      description: 'Плейсхолдер',
      type: String
    },
    tooltip: {
      description: 'Подсказка',
      type: String
    },
    required: {
      description: 'Обязательное',
      type: Boolean
    },
    isShow: {
      description: 'Скрыть',
      type: Boolean
    }
  },
  computed: {
    computedStyle () {
      let css = this.CSS
      if (this.align) {
        css += ';text-align:' + this.align
      }
      if (this.margin) {
        css += ';margin:' + this.margin
      }
      if (this.width && !this.block) {
        css += ';width:' + this.width
      }
      if (!this.block) {
        css += `;display: inline-block; width:${this.width || '200px'}`
      }
      if (this.wrapper) {
        css += ';display: block;'
      }

      return css
    }
  }
}
</script>

<style scoped>
  .date_field_width.el-date-editor.el-input{
    width: 100%;
  }
</style>
